import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import Moment from 'react-moment'

interface PostItemData {
  to: string
  img?: any
  alt?: any
  date: string
  terms: any
  className?: string
  children?: React.ReactNode
  classNameText?: string
  readMoreString?: string
  arhiveUrl: string
}

const PostItemFrontPage = ({
  to,
  img,
  alt,
  date,
  terms,
  arhiveUrl,
  className = '',
  classNameText = '',
  children,
  readMoreString = 'Lue lisää',
}: PostItemData) => {
  return (
    <div className={`flex text-gray flex-col hover-scale-img ${className}`}>
      {img && (
        <Link tabIndex={-1} to={to}>
          <GatsbyImage image={img} alt={alt} />
        </Link>
      )}
      <div className={`flex flex-1 flex-col ${classNameText}`}>
        <div className={`flex`}>
          <p className="text-xl">
            <Moment format="DD.MM.YYYY" date={date} />
          </p>
          {terms && (
            <p className="ml-auto text-xl flex">
              {terms.map((term: any, i: number, row: any) => {
                if (i + 1 === row.length) {
                  return (
                    <Link
                      to={`${arhiveUrl}?s=${term.name}`}
                      className="flex items-center hover:text-blue focus:text-blue"
                      key={term.id}
                    >
                      {term.name}
                    </Link>
                  )
                } else {
                  return (
                    <Link
                      to={`${arhiveUrl}?s=${term.name}`}
                      className="after-line-news flex items-center mr-4 hover:text-blue focus:text-blue"
                      key={term.id}
                    >
                      {term.name}
                    </Link>
                  )
                }
              })}
            </p>
          )}
        </div>
        <Link className="mt-2 hover:text-blue focus:text-blue" to={to}>
          {children}
        </Link>
        <Link
          tabIndex={-1}
          to={to}
          className="pt-6 mt-auto font-bold text-base hidden xs:inline uppercase tracking-full hover:text-blue focus:text-blue"
        >
          {readMoreString}
        </Link>
      </div>
    </div>
  )
}

export default PostItemFrontPage
