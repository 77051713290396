import React, { useState, useEffect } from 'react'
import Slider from './Slider'
import { useMediaQuery } from 'react-responsive'

interface HeroData {
  settings: any
  content: any
}

const Hero = ({ settings, content }: HeroData) => {
  const sliderTime = +settings?.time || 5000
  const sliderAni = settings?.type || 'black'

  const isMobile = useMediaQuery({
    query: '(max-width: 1279px)',
  })
  const sliderHeight: string = isMobile ? '440px' : '600px'

  /**
   * Slider functionality
   */
  const [active, setActive] = useState<any>(0)
  const [delay] = useState(sliderTime)

  useEffect(() => {
    if (content?.length > 1) {
      const interval = setInterval(() => {
        if (active + 1 === content.length) {
          setActive(0)
        } else {
          setActive((prevActive: number) => prevActive + 1)
        }
      }, delay)

      return () => clearInterval(interval)
    }
  }, [active, delay, content])

  return (
    <div>
      <div className="relative mb-48">
        <section style={{ height: sliderHeight }}>
          <Slider slides={content} active={active} sliderHeight={sliderHeight} ani={sliderAni} time={sliderTime} />
        </section>
      </div>
    </div>
  )
}

Hero.defaultProps = {
  //
}

export default Hero
