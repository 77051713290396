import React from 'react'
import Container from '@/components/Layout/Container'
import RoundedLink from '@/components/RoundedLink'
import { black, white } from '@/animations'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
interface SliderProps {
  slides: Array<any>
  active: any
  sliderHeight: string
  ani: string
  time: number
}

const Slider = ({ slides, active, sliderHeight, ani, time }: SliderProps) => {
  const height = sliderHeight

  const animation: any = ani === 'white' ? white : black

  return (
    <>
      <div className="absolute top-0 w-full" style={{ height: height }}>
        {slides.map((item: any, index: number) => {
          const title = item?.slide_title
          const image: any = getImage(item?.slide_image?.localFile)
          const altText = item?.slide_image?.altText
          const slide_class = `${active === index ? 'block opacity-100' : 'hidden opacity-0'}`

          const link = item?.gw_slider_button ? JSON.parse(item?.gw_slider_button) : ''

          return (
            <div
              key={item.slide_title + index}
              className={`${slide_class} w-full absolute ${animation[time]} transform slide`}
              style={{ height: height }}
            >
              {image && <GatsbyImage className="h-full w-full object-cover" image={image} alt={altText ?? ''} />}
              {title || link ? (
                <Container className="">
                  <div className="bg-gray relative flex flex-col justify-center place-items-center min-h-herotextsmall lg:min-h-herotext leading-none w-full lg:w-1/2 -mt-20 lg:-mt-36 z-30 py-8 lg:py-12 px-4">
                    {title && (
                      <h2 className="text-latte text-center max-w-sm md:max-w-full text-3xl md:text-5xl font-bold">
                        {title}
                      </h2>
                    )}
                    {link?.anchor && (
                      <RoundedLink
                        className="border-latte text-latte hover:bg-latte hover:text-gray focus:text-gray focus:bg-latte mt-4 xs:mt-8 text-lg sm:py-3"
                        to={link?.url}
                      >
                        {link?.anchor}
                      </RoundedLink>
                    )}
                  </div>
                </Container>
              ) : null}
            </div>
          )
        })}
      </div>
    </>
  )
}

export default Slider
