export const black = {
  4000: 'animate-blackani4',
  5000: 'animate-blackani5',
  6000: 'animate-blackani6',
  7000: 'animate-blackani7',
  8000: 'animate-blackani8',
  9000: 'animate-blackani9',
  10000: 'animate-blackani10',
}

export const white = {
  4000: 'animate-whiteani4',
  5000: 'animate-whiteani5',
  6000: 'animate-whiteani6',
  7000: 'animate-whiteani7',
  8000: 'animate-whiteani8',
  9000: 'animate-whiteani9',
  10000: 'animate-whiteani10',
}
