import React, { useEffect, useState } from 'react'
import DefaultLink from '@/components/DefaultLink'
import Facebook from '@/assets/Facebook'
import Instagram from '@/assets/Instagram'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import Moment from 'react-moment'
import { trimWords } from '@/functions'

interface SocialMediaData {
  facebook?: any
  instagram?: any
  readMoreString?: string
  somessaString?: string
}

const SocialMediaFeed = ({
  facebook,
  instagram,
  readMoreString = 'Lue lisää',
  somessaString = 'Somessa',
}: SocialMediaData) => {
  const FB: any = facebook?.group?.[0]?.nodes[0]?.feed?.data || []
  const IG: any = instagram?.nodes
  const FBname: string = facebook?.group?.[0]?.nodes?.[0]?.name || []
  const FBURL = 'https://www.facebook.com/kalevalaseura' // This would be nice if we could get this from api...

  IG?.map((some: any) => {
    some.someType = 'IG'
  })

  const NFB: any = []

  FB.map((some: any) => {
    if (some.message) {
      NFB.push({
        id: some.id,
        caption: some.message,
        localFile: '',
        permalink: 'https://www.facebook.com/kalevalaseura',
        timestamp: some.created_time,
        username: '',
        someType: 'FB',
        media_type: '',
      })
    }
  })

  const AllSome: any = [...IG, ...NFB]
  // const SOME: any = AllSome.sort((a: any, b: any) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
  const randColors: any = ['bg-blue', 'bg-green', 'bg-brown', 'bg-gray', 'bg-yellow', 'bg-black']

  const randNumber = () => {
    return Math.floor(Math.random() * (5 - 0) + 0)
  }
  const load = 6

  const numberPosts = load || 6
  const [paginatedPosts, setPaginatedPosts] = useState([...AllSome.slice(0, numberPosts)])
  const [loadMore, setLoadMore] = useState(false)

  const [hasMore, setHasMore] = useState(AllSome.length > numberPosts)

  const handleLoadMore = () => {
    setLoadMore(true)
  }

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = paginatedPosts.length
      const isMore = currentLength < AllSome.length
      const nextResults = isMore ? AllSome.slice(currentLength, currentLength + numberPosts) : []
      setPaginatedPosts([...paginatedPosts, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore])

  useEffect(() => {
    const isMore = paginatedPosts.length < AllSome.length
    setHasMore(isMore)
  }, [paginatedPosts])

  return (
    <div className="py-20">
      <h2 className="text-3xl uppercase">{somessaString}</h2>
      <div className="grid grid-cols-1 vs:grid-cols-2 lg:grid-cols-3 gap-4 mt-10">
        {paginatedPosts.map((some: any) => {
          if (some.someType === 'IG') {
            const image: any = getImage(some?.localFile)
            return (
              <DefaultLink className="relative group overflow-hidden" key={some.id} to={some.permalink}>
                <div className="overflow-hidden bg-center bg-no-repeat bg-cover">
                  <GatsbyImage className="" image={image} alt={some.caption} />
                  <div className="absolute wordbreak w-full h-full bg-fadeblue text-white opacity-1 sm:opacity-90 hover:opacity-100 group-focus:opacity-100 transition-all bottom-0 sm:group-focus:bottom-0 sm:group-hover:bottom-0 top-some-desc">
                    <div className="p-4 flex w-full h-full overflow-hidden flex-col">
                      <div className="mt-auto">
                        <p className="pl-3 sm:pl-0 leading-loose sm:leading-normal wordbreak text-sm font-bold hidden sm:inline-block md:hidden">
                          {trimWords(some.caption, 10)}
                        </p>
                        <p className="pl-3 sm:pl-0 leading-loose sm:leading-normal wordbreak text-sm font-bold hidden md:inline-block">
                          {trimWords(some.caption, 20)}
                        </p>
                      </div>
                      <div className="flex items-center pb-0 mt-auto">
                        <div className="some-feed-icon">
                          <Instagram />
                        </div>
                        <div className="ml-5">
                          <p className="font-bold text-sm leading-4">{some.username}</p>
                          <p className="text-xs leading-5">
                            <Moment format="DD.MM.YYYY HH:MM" date={some.timestamp} />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </DefaultLink>
            )
          }
          if (some.someType === 'FB') {
            return (
              <DefaultLink className="relative group overflow-hidden" key={some.id} to={FBURL}>
                <div className="square-some overflow-hidden">
                  <div
                    className={`${
                      randColors[randNumber()]
                    } absolute wordbreak w-full h-full text-white opacity-1 hover:opacity-90 group-focus:opacity-90 transition-all bottom-0`}
                  >
                    <div className="p-4 flex w-full h-full overflow-hidden flex-col">
                      <div className="flex items-center pb-0 pt-2">
                        <div className="some-feed-icon">
                          <Facebook />
                        </div>
                        <div className="ml-5">
                          <p className="font-bold text-sm leading-4">{FBname}</p>
                          <p className="text-xs leading-5">
                            <Moment format="DD.MM.YYYY HH:MM" date={some.timestamp} />
                          </p>
                        </div>
                      </div>
                      <div className="mt-auto mb-auto">
                        <p className="pl-3 sm:pl-0 leading-loose sm:leading-normal wordbreak text-sm font-bold hidden sm:inline-block">
                          {trimWords(some.caption, 25)}
                        </p>
                        <p className="pl-3 sm:pl-0 leading-loose sm:leading-normal wordbreak text-sm font-bold inline-block sm:hidden">
                          {trimWords(some.caption, 5)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </DefaultLink>
            )
            /*
            return (
              <DefaultLink className="relative group overflow-hidden" key={some.id} to={'#'}>
                <div
                  className="square-some overflow-hidden bg-center bg-no-repeat bg-cover"
                  style={{ backgroundImage: `url(${Bg})` }}
                >
                  <div className="absolute wordbreak w-full h-full bg-blue text-white opacity-1 sm:opacity-90 hover:opacity-100 group-focus:opacity-100 transition-all bottom-0 sm:group-hover:bottom-0 top-some-desc">
                      <div className="p-4 flex w-full h-full overflow-hidden flex-col">
                        <div className="mt-auto">
                          <p className="pl-3 sm:pl-0 leading-loose sm:leading-normal wordbreak text-sm font-bold">{some.caption}</p>
                        </div>
                        <div className="flex items-center pb-0 mt-auto">
                          <div className="some-feed-icon">
                            <Facebook />
                          </div>
                          <div className="ml-5">
                            <p className="font-bold text-sm leading-4">{FBname}</p>
                            <p className="text-xs leading-5"><Moment format="DD.MM.YYYY HH:MM" date={some.timestamp}/></p>
                          </div>
                        </div>
  
                      </div>
                    </div>
                </div>
              </DefaultLink>
            )
            */
          }
        })}
      </div>
      <div className="flex justify-center items-center mt-10">
        {hasMore ? (
          <button
            className="roundedButton hover:text-white hover:bg-black hover:border-black focus:text-white focus:bg-black focus:border-black"
            onClick={handleLoadMore}
          >
            {readMoreString}
          </button>
        ) : (
          <p>Ei enempää julkaisuja</p>
        )}
      </div>
    </div>
  )
}
export default SocialMediaFeed
