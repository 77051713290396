import React from 'react'
import Header from '@/components/Layout/Header'
import Footer from '@/components/Layout/Footer'
import SliderHero from '@/components/SliderHero'
import MultiSiteSelect from '@/components/Layout/MultiSiteSelect'

export interface MainLayoutProps {
  /** Page content */
  children: React.ReactNode
  hero: any
  heroSettings: any
  nav?: any
  lang?: any
  form?: any
}

/** Component shares layout structure between pages. Pass common sections like header, footer and content container here and wrap page components with it */
const LayoutHome = ({ children, heroSettings, hero, nav, lang, form }: MainLayoutProps) => {
  return (
    <>
      <Header nav={nav} lang={lang} />
      {hero.length > 0 && <SliderHero settings={heroSettings} content={hero} />}
      <main id="main" className="w-full main-height">
        {children}
      </main>
      <MultiSiteSelect />
      <Footer form={form} lang={lang} />
    </>
  )
}

LayoutHome.defaultProps = {
  location: '/',
  lang: 'fi',
}

export default LayoutHome
