import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import TextAndImage from '@/components/TextAndImage'
import EventLift from '@/components/EventLift'
import Layout from '@/components/Layout/Layout/LayoutHome'
import Container from '@/components/Layout/Container'
import NewsFeed from '@/components/NewsFeed'
import SocialMediaFeed from '@/components/SocialMediaFeed'
import { getImage } from 'gatsby-plugin-image'
import Seo from '@/components/Seo'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import moment from 'moment'
import { getArhiveUrl } from '@/functions'
import { seotype } from '@/types.d'
import SimpleFeed from '@/components/SimpleFeed'

interface HomePageData {
  data: {
    form: any
    locales: any
    page: {
      title: string
      content: string
      gwNotificationAreaButton: string
      gwNotificationAreaContent: string
      gwNotificationAreaContentUpper: string
      gwNotificationAreaImage: any
      sliderTime: {
        value: string
      }
      sliderAni: {
        value: string
      }
      heroContent: string
      translations: []
      uri: string
      seo: seotype
      gwNotificationAreaImageBg: {
        value: string
      }
      featuredImage: any
    }
    mainMenu?: any
    allWpGwEvent: any
    allWpGwPost: {
      nodes: {
        id: string
        title: string
        date: string
        uri: string
        featuredImage: any
        length: number
      }
    }
    allWpGwBook: {
      nodes: {
        gwBooksLongSummary: string
        gwBooksShortSummary: string
        id: string
        title: string
        uri: string
        featuredImage: any
      }
    }
    wp?: any
    facebook: any
    meltWater: any
    instagram: any
  }
  pageContext: {
    postlang: string
  }
}

const HomePageTemplate = ({ data, pageContext }: HomePageData) => {
  const { t, i18n } = useTranslation()

  useEffect(() => {
    i18n.changeLanguage(pageContext.postlang)
  }, [])

  // Page data
  const { page } = data
  const facebook: any = []

  // Instagram
  const { instagram } = data

  const { allWpGwEvent } = data
  const { allWpGwPost } = data
  const { allWpGwBook } = data
  const { meltWater } = data
  const { wp } = data
  const arhiveurl = getArhiveUrl(pageContext.postlang, wp.archiveurl)
  const { form } = data
  const showSome = true
  const showRss = true
  const langData = { languages: wp.languages, currentLang: pageContext.postlang, translations: page.translations }
  const { mainMenu } = data

  const books: any = allWpGwBook.nodes
  const heroSettings = { time: page?.sliderTime?.value, type: page?.sliderAni?.value }
  const heroContent = page?.heroContent

  const notifybutton = page?.gwNotificationAreaButton ? JSON.parse(page?.gwNotificationAreaButton) : ''

  const dateToday = moment().format('YYYY-MM-DD')
  const upcomingEvents: any = []

  allWpGwEvent.nodes.map((event: any) => {
    if (event.gwEventsDate >= dateToday && upcomingEvents.length < 1) {
      upcomingEvents.push(event)
    }
  })
  const notificationImage = getImage(page?.gwNotificationAreaImage?.localFile)

  return (
    <Layout form={form} heroSettings={heroSettings} hero={heroContent} nav={mainMenu} lang={langData}>
      {page.seo && (
        <Seo
          title={page?.seo?.title}
          description={page?.seo?.metaDesc}
          keywords={page?.seo?.metaKeyword}
          pathname={page?.uri}
          lang={pageContext.postlang}
          image={page?.featuredImage?.node?.localFile?.url}
          twitterTitle={page?.seo?.twitterTitle}
          twitterDescription={page?.seo?.twitterDescription}
          opengraphTitle={page?.seo?.opengraphTitle}
          opengraphDescription={page?.seo?.opengraphDescription}
          opengraphImage={page?.seo?.opengraphImage?.localFile?.url}
          twitterImage={page?.seo?.twitterImage?.localFile?.url}
        />
      )}
      <Container className="">
        <TextAndImage
          link={notifybutton?.url}
          className="my-20"
          linkText={notifybutton?.anchor}
          contentUpper={page.gwNotificationAreaContentUpper}
          img={notificationImage}
          alt={page?.gwNotificationAreaImage?.altText}
          imgBg={page.gwNotificationAreaImageBg.value}
        >
          <p>{page.gwNotificationAreaContent}</p>
        </TextAndImage>

        {/* 
            Apuraha
            <Banner 
                heading="Seuraava Apuraha Kalevalan post doc -tutkimukseen"
                buttonText="Tutustu ja hae" 
                buttonLink="#" 
                parentClass="w-full"
                buttonParentClass="sm:ml-auto sm:pl-5 sm:justify-end mt-5 sm:mt-0"
                buttonStyle="hover:bg-latte hover:text-blue hover:border-latte"
                className="bg-blue text-white p-6 sm:p-16 mb-20 flex-col sm:flex-row">
                Voit hakea apurahaa 30.5.2019 asti.
            </Banner>
        */}
        {upcomingEvents.length > 0 && (
          <EventLift
            kloString={t('klo.')}
            readMoreString={t('Lue lisää')}
            heading={t('Tapahtumat')}
            event={upcomingEvents}
          />
        )}
        {books &&
          books.map((item: any) => {
            const img = getImage(item?.featuredImage?.node?.localFile)
            const alt = item?.featuredImage.node?.altText

            return (
              <TextAndImage
                heading={item.title}
                alt={alt}
                key={item.id}
                className="mt-20 mb-12 md:mb-28"
                link={item.uri}
                linkText={t('Lue lisää')}
                contentUpper={item.gwBooksShortSummary}
                img={img}
                imgSide="left"
                imgBg={page.gwNotificationAreaImageBg.value}
              >
                <p>{item.gwBooksLongSummary}</p>
              </TextAndImage>
            )
          })}
      </Container>
      {allWpGwPost.nodes.length > 0 && (
        <Container className="bg-lighterlatte">
          <NewsFeed
            arhiveUrl={arhiveurl}
            readMoreString={t('Lue lisää')}
            headingString={t('Uutiset')}
            goToNewsString={t('Siirry uutiset sivulle')}
            posts={allWpGwPost.nodes}
          />
        </Container>
      )}
      {showSome && (
        <Container>
          <SocialMediaFeed
            readMoreString={t('Näytä lisää')}
            somessaString={t('Somessa')}
            facebook={facebook}
            instagram={instagram}
          />
        </Container>
      )}
      {showRss && (
        <Container className="bg-lighterlatte">
          <SimpleFeed
            feed={meltWater}
            headingString={t('Kalevala aiheisia uutisia muualta')}
            readMoreString={t('Lisää uutisia')}
          />
        </Container>
      )}
    </Layout>
  )
}

export const HomePageQuery = graphql`
  query HomePageById(
    $id: String!
    $postlang: [String]
    $translang: String
    $menu: [WpMenuLocationEnum]
    $formNum: Int
  ) {
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      uri
      gwNotificationAreaButton
      gwNotificationAreaContent
      gwNotificationAreaContentUpper
      gwNotificationAreaImage {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(height: 800, quality: 75)
          }
        }
      }
      ...featuredHeroImage
      sliderTime: heroSliderTime {
        value
      }
      sliderAni: heroSliderAniType {
        value
      }
      ...langdata
      ...seo
      heroContent {
        gw_slider_button
        slide_image {
          altText
          localFile {
            url
            childImageSharp {
              gatsbyImageData(aspectRatio: 1.65, height: 1200, transformOptions: { cropFocus: CENTER }, quality: 80)
            }
          }
        }
        slide_title
      }
      gwNotificationAreaImageBg {
        value
      }
    }
    allWpGwEvent(sort: { fields: gwEventsDate, order: ASC }, filter: { language: { slug: { in: $postlang } } }) {
      ...allEvents
    }
    allWpGwBook(
      limit: 1
      sort: { fields: gwBooksYear, order: DESC }
      filter: { language: { slug: { in: $postlang } } }
    ) {
      ...allFrontPageBooks
    }
    allWpGwPost(sort: { fields: date, order: DESC }, limit: 4, filter: { language: { slug: { in: $postlang } } }) {
      ...allFrontPagePosts
    }
    mainMenu: wpMenu(locations: { in: $menu }) {
      ...navigation
    }
    wp {
      ...allLang
      archiveurl: crbThemeOptions {
        archiveEn: gwPostPageUrlen
        archiveFi: gwPostPageUrlfi
        archiveSv: gwPostPageUrlsv
      }
    }
    locales: allLocale(filter: { language: { eq: $translang } }) {
      ...stringTranslations
    }
    form: wpGravityFormsForm(formId: { eq: $formNum }) {
      ...GravityFormFields
    }
    instagram: allInstagramContent(limit: 50) {
      nodes {
        id
        localFile {
          childImageSharp {
            gatsbyImageData(aspectRatio: 1, height: 800, transformOptions: { cropFocus: CENTER })
          }
        }
        caption
        permalink
        timestamp
        username
        media_type
      }
    }

    meltWater: allFeedMeltWater {
      nodes {
        title
        link
        id
        pubDate
        content
      }
    }
    #      facebook: allFacebook {
    #        group(field: id) {
    #          nodes {
    #            name
    #            feed {
    #              data {
    #                message
    #                id
    #                created_time
    #              }
    #            }
    #          }
    #        }
    #        pageInfo {
    #          currentPage
    #        }
    #      }
    #
  }
`

export default HomePageTemplate

/**
 * Facebook page data
 */
/**
 *  facebook : allFacebook {
    group(field: id) {
      nodes {
        feed {
          data {
            message
            id
            created_time
          }
        }
      }
    }
    pageInfo {
      currentPage
    }
  }
 */
/**
 * TODO :: SHOW IG
 */
/*

*/
