import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Moment from 'react-moment'
import PostItemFrontPage from '@/components/PostItem/PostItemFrontPage'

interface NewsFeedData {
  posts: {
    id: string
    title: string
    date: string
    uri: string
    featuredImage: any
  }
  headingString: string
  readMoreString: string
  arhiveUrl: string
  goToNewsString: string
}
const NewsFeed = ({
  posts,
  arhiveUrl,
  headingString = 'Uutiset',
  readMoreString = 'Lue lisää',
  goToNewsString = 'Uutiset sivulle',
}: NewsFeedData) => {
  const postdata: any = posts

  const latestpost: any = postdata[0]
  const latestpostfeaturedImageobj: any = getImage(latestpost?.firstimage?.node?.localFile)
  const terms: any = latestpost?.terms?.nodes

  return (
    <div className="news-lift py-10 w-full text-black">
      <div>
        <h2 className="text-4xl md:text-5xl break-words uppercase">{headingString}</h2>
      </div>
      <div className="w-full flex pt-6 sm:flex-row flex-col">
        <div className="w-full flex pb-6 xs:pb-0 sm:w-5/12">
          <PostItemFrontPage
            readMoreString={readMoreString}
            classNameText="mt-4"
            className="sm:pt-6"
            to={latestpost?.uri}
            img={latestpostfeaturedImageobj}
            alt={latestpost?.featuredImage?.node?.altText}
            date={latestpost?.date}
            terms={terms}
            arhiveUrl={arhiveUrl}
          >
            <h3 className="sm:text-2xl md:text-3xl">{latestpost?.title}</h3>
          </PostItemFrontPage>
        </div>
        <div className="w-full sm:w-7/12">
          <div className="sm:ml-8">
            {postdata.map((item: any, index: number) => {
              const featuredImageobj: any = getImage(item?.featuredImage?.node?.localFile)
              const alt: string = item?.featuredImage?.node?.altText
              if (index > 0) {
                return (
                  <Link
                    key={item.id}
                    className="flex xs:flex-row flex-col hover-scale-img xs:border-b border-black py-6 hover:text-blue focus:text-blue"
                    to={item.uri}
                  >
                    {featuredImageobj && (
                      <GatsbyImage
                        className="w-full flex-shrink-0 xs:w-1/3 clip-y"
                        image={featuredImageobj}
                        alt={alt}
                      />
                    )}
                    <div className="xs:ml-6 flex flex-col mt-4 xs:mt-0">
                      <p>
                        <Moment format="DD.MM.YYYY" date={item.date} />
                      </p>
                      <h3 className="mt-2">{item.title}</h3>
                      <p className="mt-auto font-bold text-base hidden xs:inline uppercase tracking-full">
                        {readMoreString}
                      </p>
                    </div>
                  </Link>
                )
              }
            })}
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center mt-14">
        <Link
          className="roundedButton hover:bg-black hover:text-white focus:text-white focus:bg-black hover:border-black"
          to={arhiveUrl}
        >
          {goToNewsString}
        </Link>
      </div>
    </div>
  )
}

export default NewsFeed
