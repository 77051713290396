import React from 'react'
import LoadMore from '@/components/LoadMore'

interface Feed {
  title: string
  link: string
  id: string
  pubDate: string
  content: string
}
interface SimpleFeedData {
  status?: boolean
  headingString?: string
  readMoreString?: string
  feed?: {
    nodes: Array<Feed>
  }
}

const SimpleFeed = ({
  status,
  headingString = 'Kalevala aiheisia uutisia muualta',
  readMoreString = '',
  feed,
}: SimpleFeedData) => {
  if (status === false) {
    return null
  }

  return (
    <div className="py-14">
      <h2 className="text-3xl uppercase">{headingString}</h2>
      <LoadMore
        buttonText={readMoreString}
        classNameButton="flex justify-center items-center mt-10"
        postType="MeltWater"
        className="sm:px-10 pt-10"
        posts={feed?.nodes}
      />
    </div>
  )
}
export default SimpleFeed
